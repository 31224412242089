@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');


@font-face {
    font-family: 'MyFont';
    src: local('MyFont'), url(../Assets/PirateShip.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }


body{
    background-color: #030d24;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}

.slide_11 {
    width: 900px !important ;
}

.w400{  font-family: 'Poppins', sans-serif;  font-weight: 400;}
.w500{  font-family: 'Poppins', sans-serif;  font-weight: 500;}
.w600{  font-family: 'Poppins', sans-serif;  font-weight: 600;}
.w700{  font-family: 'Poppins', sans-serif;  font-weight: 700;}

.f_55{  font-size: 55px; }
.f_45{  font-size: 45px; }
.f_40{  font-size: 40px; }
.f_34{  font-size: 34px; }
.f_32{  font-size: 32px; }
.f_28{  font-size: 28px; }
.f_26{  font-size: 26px; }
.f_24{  font-size: 24px; }
.f_22{  font-size: 22px; }
.f_20{  font-size: 20px; }
.f_18{  font-size: 18px; }
.f_16{  font-size: 16px; }
.f_14{  font-size: 14px; }
.f_12{  font-size: 12px; }
.f_10{  font-size: 10px; }

.myfont{font-family: 'MyFont'}

.color_t{color: #ff8500;}
.color_s{color: #030d24;}

.button_ac{
    background-color: #ff8500;
    text-decoration: none;
    color: #030d24;
}
.button_ac:hover{
    background-color: #ff8500;
    text-decoration: none;
    color: #030d24;
}

.button_pas{
    background-color: #460027;
    text-decoration: none;
    color: #FFF;
}

.button_pas:hover{
    background-color: #460027;
    text-decoration: none;
    color: #FFF;
}

.bg_k{background-color: #460027;}

.header_div_full{
    background: rgb(97,13,74);
    background: linear-gradient(270deg, rgba(97,13,74,1) 0%, rgba(49,0,19,1) 100%);
    position: fixed;
    left: 0px; right: 0px; top: 0px;
    padding: 10px;
    z-index: 4;
}

.cont_mt{margin-top: 140px;}

.gal_im{
    width: 180px;
    height: 180px;
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
}
.gal_im:hover{
    width: 180px;
    height: 180px;
    background-size: cover;
    background-position: center;
    filter: grayscale(0%);
}

.photo_btn{
    background-color: #460027; 
    padding: 3px 12px; 
    border-radius: 10px;
}

.cont_w{
    width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.lang_selected{
    position: absolute;
    z-index: 4;
    top: 25px;
    right: 6px;
}

.lang_menu{
    position: absolute;
    z-index: 3;
    background-color: #FFF;
    padding: 45px 3px 3px 3px;
    right: 3px;
    top: 15px;
    width: 36px;
    border-radius: 5px;
}

.header_link{
    font-size: 20px;
    color: #FFF;
    text-decoration: none;
    position: relative;
    margin-left: 45px;

}


.header_link::before {
    content: "";
    position: absolute;
    left: -3px;
    bottom: -5px;
    width: 106%;
    height: 2px;
    background: #FFF;
    z-index: 1;
    transform: rotate(4deg);
    transition: all 0.1s ease;

}

.header_link:hover{
    color: #ff8500;
    text-decoration: none;
    position: relative;


}


.header_link:hover::before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -2px;
    width: 100%;
    height: 5px;
    background: #ff8500;
    z-index: 1;
    transform: rotate(0deg);
    transition: all 0.1s ease;


}

.nedir_box{
    border: 1px #ff8500 solid;
    padding: 20px;
}
.puan_box{
    border: 1px #ff8500 solid;
    max-height: 1150px;
    overflow-y: auto;
}
.footer_box{
    background-color: #240037;
    background-image: url('../Assets/line_33.png');
    background-repeat: repeat-x;
    background-size: 40px;
}
.footer_box2{
    background-image: url('../Assets/line_33.png');
    background-repeat: repeat-x;
    background-size: 40px;
    background-position: bottom;
}


.ilet_box{
    border: 1px #ff8500 solid;
}

.addres_pad{
    padding-left: 80px;
    padding-right: 80px;
}

.border_right_w{
    border-right: 2px #FFF solid;
}
.border_bottom_tur{
    border-bottom: 1px #ff8500 solid;
}

.banner_arrow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.minh{
    max-height: 350px;
    overflow-y: auto;
}

.scrl::-webkit-scrollbar {
width: 10px;
}

.scrl::-webkit-scrollbar-track {
    background-color: #6c3f14;
}

.scrl::-webkit-scrollbar-thumb {
background-color: #ff8500;
}

.baslik_b{
    border-top: 2px #FFF solid;
    padding-top: 40px;
}



.dash_menu_ul{
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.cursor{
    cursor: pointer;
}

.mobile_menu_div{
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(3, 13, 36, 0.8);
    z-index : 100
}

.lang_selected_mobile{
    border: 3px  #ff8500 solid;
    border-radius: 20px;
}


@media (max-width: 900px)  { 

    .cont_w{
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }

    .gal_im{
        width: 116px;
        height: 116px;
        background-size: cover;
        background-position: center;
        filter: grayscale(100%);
    }
    .gal_im:hover{
        width: 116px;
        height: 116px;
        background-size: cover;
        background-position: center;
        filter: grayscale(0%);
    }


    
.addres_pad{
    padding-left: 30px;
    padding-right: 30px;
}

.cont_mt{margin-top: 100px;}

.puan_box{
    border: 1px #ff8500 solid;
    max-height: 960px;
    overflow-y: auto;
}

}